<template>
    <li @click="$emit('loadConversation', conversation)">
        <avatar 
            :image-url="conversation.participant.imageUrl" 
            :image-alt="conversation.participant.name"
            :user-id="conversation.participant.id"
        ></avatar>
        <div class="chat-info flex-grow-1">
            <h5 class="mb-0">{{ conversation.participant.name  }}</h5>
            <p class="card-text text-truncate">
                {{ conversation.message.body }}
            </p>
        </div>
        <div class="chat-meta text-nowrap">
            <small class="float-right mb-25 chat-time">{{ conversation.message.time }}</small>
            <span v-show="conversation.unread_count" class="badge badge-danger badge-pill float-right">
                {{ conversation.unread_count }}
            </span>
        </div>
    </li>
</template>
<script>
import Avatar from "../avatars/Avatar.vue"

export default {
    name: "Conversation",
    components: {
        Avatar
    },
    props: {
        conversation: {
            type: Object,
            reuired: true
        },
    },
    data() {
        return {
            status: 'avatar-status-offline'
        }
    }
}
</script>