$(function () {
    $("#education").validate({
        rules: {
            academic_level_id: {
                required: true
            },
            course: {
                required: true,
                maxlength: 255
            },
            school: {
                required: true,
                maxlength: 255
            },
            date_completed: {
                required: true
            },
            certificate: {
                required: true,
                accept: "image/*"
            }
        },
        messages: {
            certificate: {
                accept: "Upload images only"
            }
        },
        errorPlacement: function (error, element) {
            if (element.attr('id') === 'date_completed') {
                $(element).closest('.form-group').append(error);
            } else if (element.attr('id') === 'certificate') {
                $(element).closest('.form-group').append(error);
            }
            else if (element.attr('type') === 'radio' || element.attr('type') === 'checkbox') {
                $(element).closest('div').parent().append(error);
            } else {
                $(element).closest('div').append(error);
            }
        },
        submitHandler: function (form) {
            form.submit();
        }
    });
});