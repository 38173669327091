<template>
    <div class="sc-message-box sc-message-window">
        <chat-window 
        :participants="participants" 
        :title="chatWindowTitle" 
        :on-user-input-submit="sendMessage"
        :message-list="messageList" 
        :is-open="true" 
        :show-header="showHeader"
        placeholder="Write a message..."
        :show-typing-indicator="showTypingIndicator" 
        :colors="colors" 
        :image="image" 
        :always-scroll-to-bottom="alwaysScrollToBottom"
        :message-styling="true" 
        :show-confirmation-deletion="true"
        confirmation-deletion-message="Are you sure you want to delete message?" 
        @close="$emit('close', id)"
        @input-focused="readMessages"
        @scrollToTop="handleScrollToTop"
        @onType="whisperTyping"></chat-window>
    </div>
</template>
<script>
import store from './store/'
import ChatWindow from './ChatWindow.vue'

export default {
    components: {
        ChatWindow
    },
    props: {
        conversation:{
            type: Object,
            required: true
        },
        showHeader: {
            type: Boolean,
            default: false
        },
        colors: {
            type: Object,
            validator: (c) =>
                'header' in c &&
                'bg' in c.header &&
                'text' in c.header &&
                'launcher' in c &&
                'bg' in c.launcher &&
                'messageList' in c &&
                'bg' in c.messageList &&
                'sentMessage' in c &&
                'bg' in c.sentMessage &&
                'text' in c.sentMessage &&
                'receivedMessage' in c &&
                'bg' in c.receivedMessage &&
                'text' in c.receivedMessage &&
                'userInput' in c &&
                'bg' in c.userInput &&
                'text' in c.userInput,
            default: function () {
                return {
                    header: {
                        bg: '#ffffff',
                        text: '#5e5873'
                    },
                    launcher: {
                        bg: '#4e8cff'
                    },
                    messageList: {
                        bg: '#f2f0f7'
                    },
                    sentMessage: {
                        bg: 'linear-gradient(80deg, #7367f0, #9e95f5)',
                        text: '#ffffff'
                    },
                    receivedMessage: {
                        bg: '#ffffff',
                        text: '#6e6b7b'
                    },
                    userInput: {
                        bg: '#ffffff',
                        text: '#565867'
                    }
                }
            }
        }
    },
    data: () => {
        return {
            title: '',
            participants: [],
            messageList: [],
            showTypingIndicator: '',
            alwaysScrollToBottom: true,
            image: {
                'imageUrl': false
            },
        }
    },
    created() {
        this.getMessages()
    },
    mounted() {
        window.Echo.private('chat.' + this.conversation.id)
            .listen('.message.created', (message) => {
                this.messageList = [...this.messageList, message];
                this.playSound('/assets/audio/notify.mp3');
            })
            .listenForWhisper('typing', (e) => {
                this.showTypingIndicator = e.user.name;

                setTimeout(() => {this.showTypingIndicator = '';}, 900);
            });

            this.image = {
                'imageUrl': this.conversation.participant.imageUrl,
                'imageAlt': this.conversation.participant.name,
                'user_id': this.conversation.participant.id,
            }
    },
    computed: {
        chatWindowTitle() {
            if (this.title !== '') return this.title

            if (this.participants.length === 0) return 'You'
            if (this.participants.length > 1) return 'You, ' + this.participants[0].name + ' & others'

            return 'You & ' + this.participants[0].name
        }
    },
    watch: {
        $props: {
            deep: true,
            immediate: true,
            handler(props) {
                for (const prop in props) {
                    store.setState(prop, props[prop])
                }
            }
        },
        conversation(){
            this.getMessages()
        }
    },
    methods: {
        playSound(sound) {
            if (sound) {
                var audio = new Audio(sound);
                audio.play();
            }
        },
        getMessages(){
            axios.get(`/chat/${this.conversation.id}`).then(result => {
                this.participants =  result.data.participants;
                this.messageList =  result.data.messages;
            }).catch(error => {
                console.log(error);
            });
        },
        sendMessage(message) {
            if (message) {
                axios.post('/chat/' + this.conversation.id, {
                    text: message.data.text
                });

                this.onMessageWasSent(message)
            }
        },
        onMessageWasSent(message) {
            this.messageList = [...this.messageList, message]
        },
        readMessages() {
            axios.post('/chat/' + this.conversation.id + '/read');
        },
        handleScrollToTop() {
            // called when the user scrolls message list to top
            // leverage pagination for loading another page of messages
        },
        whisperTyping() {
            let channel = Echo.private('chat.' + this.conversation.id);

            setTimeout(() => {
                channel.whisper('typing', {
                    user: window.Laravel.user,
                    typing: true
                });
            }, 300);
        }
    }
}
</script>  

<style>
.sc-message-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
}

.sc-message-box .sc-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
}

.sc-message-box .message-list ,
.sc-message-box .user-list {
    height: 100%;
}

.sc-message-box .sc-message {
    width: auto;
}
</style>
