<template>
	<span v-if="parseInt(notificationsCount) > 0" id="notification-counter" class="notification">
		{{ notificationsCount }}
	</span>
</template>

<script>
import axios from 'axios';

export default {
	name: "UnreadNotificationsCount",
	props: ['count'],
	data: function () {
		return {
			notificationsCount: this.count,
		}
	},
	mounted() {
		window.Echo.private('notifications.' + window.Laravel.user.id)
			.listen('.notifications.created', (e) => {
				this.getUnreadNotificationsCount();
			});
	},
	methods: {
		getUnreadNotificationsCount() {
			axios.get(`/notifications/unread-count`).then(result => {
				this.notificationsCount = result.data.count;
			}).catch(error => {
				console.log(error);
			})
		}
	}
}
</script>