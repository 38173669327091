<template>
    <div class="sc-chat-box sc-chat-window">
        <chat-window 
        :participants="participants" 
        :title="chatWindowTitle" 
        :on-user-input-submit="sendMessage"
        :message-list="messageList" 
        :is-open="true" 
        :show-header="showHeader"
        placeholder="Write a message..."
        :show-typing-indicator="showTypingIndicator" 
        :colors="colors" 
        :always-scroll-to-bottom="alwaysScrollToBottom"
        :message-styling="true" 
        :show-confirmation-deletion="true"
        confirmation-deletion-message="Are you sure you want to delete message?" 
        @close="$emit('close', id)"
        @input-focused="readMessages"
        @scrollToTop="handleScrollToTop"
        @onType="whisperTyping"></chat-window>
    </div>
</template>
<script>
import store from './store/'
import ChatWindow from './ChatWindow.vue'

export default {
    components: {
        ChatWindow
    },
    props: {
        conversationId:{
            type: Number,
            required: true
        },
        showHeader: {
            type: Boolean,
            default: false
        },
        colors: {
            type: Object,
            validator: (c) =>
                'header' in c &&
                'bg' in c.header &&
                'text' in c.header &&
                'launcher' in c &&
                'bg' in c.launcher &&
                'messageList' in c &&
                'bg' in c.messageList &&
                'sentMessage' in c &&
                'bg' in c.sentMessage &&
                'text' in c.sentMessage &&
                'receivedMessage' in c &&
                'bg' in c.receivedMessage &&
                'text' in c.receivedMessage &&
                'userInput' in c &&
                'bg' in c.userInput &&
                'text' in c.userInput,
            default: function () {
                return {
                    header: {
                        bg: '#4e8cff',
                        text: '#ffffff'
                    },
                    launcher: {
                        bg: '#4e8cff'
                    },
                    messageList: {
                        bg: '#ffffff'
                    },
                    sentMessage: {
                        bg: '#4e8cff',
                        text: '#ffffff'
                    },
                    receivedMessage: {
                        bg: '#f4f7f9',
                        text: '#ffffff'
                    },
                    userInput: {
                        bg: '#f1f1f1',
                        text: '#565867'
                    }
                }
            }
        }
    },
    data: () => {
        return {
            title: '',
            participants: [],
            messageList: [],
            showTypingIndicator: '',
            alwaysScrollToBottom: false,
        }
    },
    created() {
        this.getMessages()
    },
    mounted() {
        window.Echo.private('chat.' + this.conversationId)
            .listen('.message.created', (message) => {
                this.messageList = [...this.messageList, message];
                this.playSound('/assets/audio/notify.mp3');
            })
            .listenForWhisper('typing', (e) => {
                this.showTypingIndicator = e.user.name;

                setTimeout(() => {this.showTypingIndicator = '';}, 900);
            });
    },
    computed: {
        chatWindowTitle() {
            if (this.title !== '') return this.title

            if (this.participants.length === 0) return 'You'
            if (this.participants.length > 1) return 'You, ' + this.participants[0].name + ' & others'

            return 'You & ' + this.participants[0].name
        }
    },
    watch: {
        $props: {
            deep: true,
            immediate: true,
            handler(props) {
                for (const prop in props) {
                    store.setState(prop, props[prop])
                }
            }
        }
    },
    methods: {
        playSound(sound) {
            if (sound) {
                var audio = new Audio(sound);
                audio.play();
            }
        },
        getMessages(){
            axios.get(`/chat/${this.conversationId}`).then(result => {
                this.participants =  result.data.participants;
                this.messageList =  result.data.messages;
            }).catch(error => {
                console.log(error);
            });
        },
        sendMessage(message) {
            if (message) {
                axios.post('/chat/' + this.conversationId, {
                    text: message.data.text
                });

                this.onMessageWasSent(message)
            }
        },
        onMessageWasSent(message) {
            this.messageList = [...this.messageList, message]
        },
        readMessages() {
            axios.post('/chat/' + this.conversationId + '/read');
        },
        handleScrollToTop() {
            // called when the user scrolls message list to top
            // leverage pagination for loading another page of messages
        },
        whisperTyping() {
            let channel = Echo.private('chat.' + this.conversationId);

            setTimeout(() => {
                channel.whisper('typing', {
                    user: window.Laravel.user,
                    typing: true
                });
            }, 300);
        }
    }
}
</script>  

<style>
.sc-chat-box {
    width: 100%;
    text-align: initial;
    box-sizing: border-box;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sc-chat-box .sc-message-list {
    overflow-y: auto;
    max-height: 350px;
}

.sc-chat-box .sc-message {
    width: auto;
}
</style>
