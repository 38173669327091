$(document).ready(function () {
    $("#paymentMethod").change(function () {
        let method = $(this).val();

        if(method.length === 0){
            return false;
        }

        $(".payment-method").hide();
        $("#"+method).show();
    });
});