$(function () {
    $("#essay-submit").validate({
        rules: {
            essay: {
                required: true,
                accept: "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            }
        },
        messages: {
            essay: {
                accept: "Please upload a word document."
            }
        },
        errorPlacement: function (error, element) {
            if(element.attr('id') === 'essay'){
                $(element).closest('.form-group').append(error);
            }
        },
        submitHandler: function (form) {
            form.submit();
        }
    });
});