<template>
    <div id="essay" class="margin-top-10 padding-left-10 padding-top-10">
        <timer v-show="start" :units="['days','hours', 'minutes', 'seconds']" class="countdown"
               :class="{'ending': ending}"
               @starting="ending = false"
               @timeEnding="ending = true"
               @end="submitAnswer"
               :seconds="currentQuestion.time" :start="start">
        </timer>
    </div>
</template>

<script>
    import timer from "../CountDown";

    export default {
        name: "essay",
        props: ["question", "questionUrl"],
        data() {
            return {
                start: true,
                ending: false,
                currentQuestion: this.question
            }
        },
        components: {
            timer
        },
        methods: {
            submitAnswer() {
                window.location.reload();
            },
            errorMessage(message) {
                return swal({
                    title: 'Error',
                    text: message,
                    type: "warning",
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                    allowEscapeKey: false
                })
            }
        }
    }
</script>