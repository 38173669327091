<template>
    <span class="avatar" :class="classes">
        <img :src="imageUrl" :height="height" :width="width" :alt="imageAlt">
        <span :class="status"></span>
    </span>
</template>
<script>
export default {
    name: "Avatar",
    props: {
        userId: {
            type: Number,
            required: true
        },
        imageUrl: {
            type: String,
            required: true
        },
        imageAlt: {
            type: String,
            required: false
        },
        classes: {
            type: String,
            required: false
        },
        height: {
            type: String,
            default: "42"
        },
        width: {
            type: String,
            default: "42"
        }
    },
    data() {
        return {
            status: 'avatar-status-offline'
        }
    },
    mounted() {
        window.Echo.join('online')
            .listen('.user.online', (e) => {
                if(e.user.id == this.userId){
                    this.status = 'avatar-status-online'
                }
            })
            .listen('.user.offline', (e) => {
                if(e.user.id == this.userId){
                    this.status = 'avatar-status-offline'
                }
            });
    }
}
</script>