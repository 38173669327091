$(document).ready(function () {
    $('#unrequested').on("click", '.show_fine_removal_modal', function (event) {
        let fine_id = $(this).data('fine-id');
        let modal = $('#fine_removal_modal');

        modal.find('.modal-body :input').val('');
        $('input[name=fine_id]').val(fine_id);

        modal.modal('show');
    })
});