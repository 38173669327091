window.Popper = require('popper.js').default;

window.$ = window.jQuery = require('jquery');

require('bootstrap');

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

require('select2');
require('jquery.easing');
require('jquery-multifile');
require('jquery-validation');
require('jquery-validation/dist/additional-methods');
require('datatables.net');
require('datatables.net-bs4');
require("intl-tel-input/build/js/intlTelInput-jquery");
require('bootstrap-datepicker');
require('jquery.scrollbar');
require('jquery-scroll-lock');

window.Aos = require('aos');
window.Swal = require('sweetalert2');
window.Cookies = require('js-cookie');
window.moment = require('moment');
require('moment-timezone');

window.totalPopups = 0;
window.popups = [];


import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true
});

import { createApp } from 'vue'

import ChatToggle from './components/chat/Toggle.vue';
import ChatBox from './components/chat/ChatBox.vue';
import MessageCounter from './components/counters/messages';
import NotificationCounter from './components/counters/notifications';
import Language from './components/evaluation/Language.vue';
import Essay from './components/evaluation/Essay.vue';
import CountDown from './components/evaluation/Question.vue';
import Conversations from './components/messages/Conversations'

if (window.Laravel.user) {

    window.Echo.join('online')
        .joining((user) => {
            axios.put('/user/' + user.id + '/online', {});
        })
        .leaving((user) => {
            axios.put('/user/' + user.id + '/offline', {});
        });

    createApp({
        components: {
            'chat-toggle': ChatToggle,
            'chat-box': ChatBox,
            'message-counter': MessageCounter,
            'notification-counter': NotificationCounter,
            'language': Language,
            'essay': Essay,
            'count-down': CountDown,
            'conversations': Conversations,
        }
    }).mount('#app')
}