$(function () {
    $("#skills").validate({
        ignore:[],
        rules: {
            past_writer: {
                required: true
            },
            'citations[]': {
                required: true,
            },
            'disciplines[]': {
                required: true,
                minlength: 5,
                maxlength: 15,
            }
        },
        messages: {
            'citations[]': {
                required: "Please select at least one option"
            },
            'disciplines[]': {
                required: "Please select field of expertise",
                minlength: "Please select at least 5 options",
                maxlength: "Please select a maximum of 15 options"
            },
        },
        errorPlacement: function (error, element) {
            if (element.attr("name") === "citations[]") {
                error.insertAfter("#form_citation_error");
            } else if (element.attr("name") === "disciplines[]") {
                error.insertAfter("#form_discipline_error");
            } else {
                $(element).closest('div').append(error);
            }
        },
        submitHandler: function (form) {
            form.submit();
        }
    });
});