<template>
    <div class="chat-application">
        <div class="content-area-wrapper">
            <div class="sidebar-left">
                <div class="sidebar">
                    <div class="sidebar-content">
                        <div id="users-list" class="chat-user-list-wrapper list-group ps ps--active-y">
                            <h5 class="chat-list-title">Chats</h5>
                            <ul class="chat-users-list chat-list media-list">
                                <conversation v-for="conversation in conversations" :key="conversation.id"
                                    @load-conversation="setConversation" :conversation="conversation"></conversation>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-right">
                <div class="content-wrapper">
                    <div class="content-body">
                        <div class="chat-app-window">
                            <div v-if="landing" class="start-chat-area">
                                <div class="mb-1 start-chat-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-message-square">
                                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                    </svg>
                                </div>
                                <h4 class="sidebar-toggle start-chat-text" data-bs-toggle="modal" data-bs-target="#new_message">Start Conversation</h4>
                            </div>
                            <div v-else class="active-chat">
                                <message-box 
                                    :conversation="conversation"
                                    :show-header="true"
                                ></message-box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MessageBox from '../chat/MessageBox.vue';
import Conversation from './Conversation.vue';

export default {
    name: "Conversations",
    props: {
        conversations: {
            type: Array,
            default: [],
        }
    },
    components: {
        MessageBox,
        Conversation
    },
    data() {
        return {
            landing: true,
            conversation: {}
        }
    },
    methods: {
        setConversation(conversation) {
            this.conversation = conversation
            this.landing = false
        }
    }
}
</script>