$(function () {
    let countryData = window.intlTelInputGlobals.getCountryData(),
        telInput = $("#phone"),
        countries = $("#countryCode");

    telInput.intlTelInput(telInput, {
        nationalMode: true,
        utilsScript: require('intl-tel-input/build/js/utils')
    });

    $.each(countryData, function (i, country) {
        countries.append($("<option></option>").attr("value", country.iso2).text(country.name));
    });

    let initialCountry = telInput.intlTelInput("getSelectedCountryData").iso2;
    countries.val(initialCountry);


    telInput.on("countrychange", function () {
        countries.val(telInput.intlTelInput("getSelectedCountryData").iso2);
        countries.trigger('change');
    });

    countries.change(function () {
        telInput.intlTelInput("setCountry", $(this).val());
        telInput.valid()
    });


    jQuery.validator.addMethod("validPhone", function () {
        return telInput.intlTelInput("isValidNumber");
    }, 'Please enter a valid phone number.');

    /**
     * Initialize the validations for the forms.
     */
    $("#editProfileForm").validate({
        rules: {
            phone: {
                required: true,
                validPhone: true
            },
            countryCode: {
                required: true,
            }
        },
        errorPlacement: function (error, element) {
            if (element.attr('id') === 'phone') {
                $('.iti').after(error);
            } else {
                $(element).closest('div').append(error);
            }
        },
        submitHandler: function (form) {
            $("#phoneNumber").val($("#phone").intlTelInput("getNumber"));
            form.submit();
        }
    });

    $("#profile").validate({
        rules: {
            phone: {
                required: true,
                validPhone: true
            },
            country_code: {
                required: true,
            },
            document: {
                required: true,
                accept: "image/*"
            }
        },
        messages: {
            document: {
                accept: "Upload images only"
            }
        },
        errorPlacement: function (error, element) {
            if (element.attr('id') === 'phone') {
                $('.iti').after(error);
            } else {
                $(element).closest('div').append(error);
            }
        },
        submitHandler: function (form) {
            $("#phone_number").val($("#phone").intlTelInput("getNumber"));
            form.submit();
        }
    });
});