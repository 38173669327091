<template>
    <div class="card">
        <div class="card-body">
            <div class="margin-top-15">
                <question v-if="showQuestion" :user-evaluation-id="userEvaluation.id" :question="question"
                          :question-url="questionUrl">
                </question>
                <div v-else id="description">
                    <p>{{ userEvaluation.evaluation.instructions }}</p>
                    <button class="btn btn-primary btn-round" @click="startTest">
                        <i class="bx bx-fast-forward-circle"></i>
                        Start Test
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import question from "./Question";

    export default {
        name: "evaluation",
        props: ['evaluationData', 'startUrl', 'questionUrl'],
        data() {
            return {
                question: '',
                userEvaluation: {},
                showQuestion: false
            }
        },
        components: {
            question
        },
        methods: {
            startTest() {
                axios.post(this.startUrl, this.userEvaluation).then((response) => {
                    this.question = response.data;
                    this.showQuestion = true;
                }).catch((error) => {
                    this.errorMessage(error.data.message);
                });
            },
            errorMessage(message) {
                return swal({
                    title: 'Error',
                    text: message,
                    type: "warning",
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                    allowEscapeKey: false
                })
            }
        },
        created: function () {
            this.userEvaluation = this.evaluationData.evaluation;
            if (this.evaluationData.type === "question") {
                this.question = this.evaluationData.question;
                this.showQuestion = true;
            }
        }
    }
</script>