require('./vendor');
require('./app');
require('./forms');
require('./messages');

Array.remove = function(array, from, to) {
    var rest = array.slice((to || from) + 1 || array.length);
    array.length = from < 0 ? array.length + from : from;
    return array.push.apply(array, rest);
};

Number.prototype.formatMoney = function (c, d, t) {
    let n = this,
        cc = isNaN(c = Math.abs(c)) ? 2 : c,
        de = d === undefined ? "." : d,
        th = t === undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(cc)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + th : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + th) + (cc ? de + Math.abs(n - i).toFixed(cc).slice(2) : "");
};

$(document).ready(function () {
    //  Activate the Tooltips
    $('[data-toggle="tooltip"], [rel="tooltip"]').tooltip();

    // Activate Popovers
    $('[data-toggle="popover"]').popover();

    // Activate select 2
    $('select.form-control').select2();

    // Validate form on select
    $('select').on('select2:select', function (e) {
        $(this).valid();
    });

    Aos.init();

    // Add validation to form
    $("form.validate-form").validate();


    $('.timezone').val(moment.tz.guess());

    $("input[type=file]").change(function () {
        let fileName = $(this).val();
        fileName = fileName.replace("C:\\fakepath\\", "");
        $(this).next('.custom-file-label').html(fileName);
    });

    // Make order rows click able.
    $("body").on("click", ".dataTable .dt-order-item", function () {
        window.location = $(this).data("href");
    });

    // Handle setting the active class on menu
    let url = window.location;
    $('ul.nav a').filter(function () {
        return this.href == url;
    }).parent().addClass('active');

    $(".input-group.date").datepicker({autoclose: true, format: 'yyyy-mm-dd'});

    $(document).ajaxError(function (event, jqxhr, settings, exception) {
        if (exception === 'Unauthorized') {
            Swal({
                title: "Session Time Out",
                text: "Your session has timed out, Please log in again.",
                type: "warning",
            }).then(() => {
                window.location.replace('/login');
            });
        } else {
            console.log(exception);
        }
    });

    $.fn.dataTable.ext.errMode = 'none';
});