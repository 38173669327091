<template>
    <div class="sc-chat-popup" :id="id">
        <chat-window 
            :id="id" 
            :participants="participants" 
            :title="chatWindowTitle" 
            :on-user-input-submit="sendMessage"
            :message-list="messageList" 
            :is-open="true" 
            placeholder="Write a message..."
            :show-typing-indicator="showTypingIndicator" 
            :colors="colors" 
            :always-scroll-to-bottom="alwaysScrollToBottom"
            :message-styling="true" 
            :show-confirmation-deletion="true"
            confirmation-deletion-message="Are you sure you want to delete message?" 
            @input-focused="readMessages"
            @close="$emit('close', id)"
            @scrollToTop="handleScrollToTop"
            @onType="whisperTyping"></chat-window>
    </div>
</template>
<script>
import store from './store/'
import ChatWindow from './ChatWindow.vue'

export default {
    components: {
        ChatWindow
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        bid: {
            type: Object,
            required: true,
        },
        showCloseButton: {
            type: Boolean,
            default: true
        },
        showEdition: {
            type: Boolean,
            default: false
        },
        showDeletion: {
            type: Boolean,
            default: false
        },
        colors: {
            type: Object,
            validator: (c) =>
                'header' in c &&
                'bg' in c.header &&
                'text' in c.header &&
                'launcher' in c &&
                'bg' in c.launcher &&
                'messageList' in c &&
                'bg' in c.messageList &&
                'sentMessage' in c &&
                'bg' in c.sentMessage &&
                'text' in c.sentMessage &&
                'receivedMessage' in c &&
                'bg' in c.receivedMessage &&
                'text' in c.receivedMessage &&
                'userInput' in c &&
                'bg' in c.userInput &&
                'text' in c.userInput,
            default: function () {
                return {
                    header: {
                        bg: '#4e8cff',
                        text: '#ffffff'
                    },
                    launcher: {
                        bg: '#4e8cff'
                    },
                    messageList: {
                        bg: '#ffffff'
                    },
                    sentMessage: {
                        bg: '#4e8cff',
                        text: '#ffffff'
                    },
                    receivedMessage: {
                        bg: '#f4f7f9',
                        text: '#ffffff'
                    },
                    userInput: {
                        bg: '#f1f1f1',
                        text: '#565867'
                    }
                }
            }
        }
    },
    data: () => {
        return {
            title: '',
            participants: [],
            messageList: [],
            showTypingIndicator: '',
            alwaysScrollToBottom: false,
        }
    },
    created() {
        this.getMessages()
    },
    mounted() {
        window.Echo.private('chat.' + this.bid.conversation_id)
            .listen('.message.created', (message) => {
                this.messageList = [...this.messageList, message];
                this.playSound('/assets/audio/notify.mp3');
            })
            .listenForWhisper('typing', (e) => {
                this.showTypingIndicator = e.user.name;

                setTimeout(() => {this.showTypingIndicator = '';}, 900);
            });
    },
    computed: {
        chatWindowTitle() {
            if (this.title !== '') return this.title

            if (this.participants.length === 0) return 'You'
            if (this.participants.length > 1) return 'You, ' + this.participants[0].name + ' & others'

            return 'You & ' + this.participants[0].name
        }
    },
    watch: {
        $props: {
            deep: true,
            immediate: true,
            handler(props) {
                for (const prop in props) {
                    store.setState(prop, props[prop])
                }
            }
        }
    },
    methods: {
        playSound(sound) {
            if (sound) {
                var audio = new Audio(sound);
                audio.play();
            }
        },
        getMessages(){
            axios.get(`/chat/${this.bid.conversation_id}`).then(result => {
                this.participants =  result.data.participants;
                this.messageList =  result.data.messages;
            }).catch(error => {
                console.log(error);
            });
        },
        sendMessage(message) {
            if (message) {
                axios.post('/chat/' + this.bid.conversation_id, {
                    text: message.data.text
                });

                this.onMessageWasSent(message)
            }
        },
        onMessageWasSent(message) {
            this.messageList = [...this.messageList, message]
        },
        handleScrollToTop() {
            // called when the user scrolls message list to top
            // leverage pagination for loading another page of messages
        },
        readMessages() {
            axios.post('/chat/' + this.bid.conversation_id + '/read');
        },
        whisperTyping() {
            let channel = Echo.private('chat.' + this.bid.conversation_id);
            setTimeout(() => {
                channel.whisper('typing', {
                    user: window.Laravel.user.id,
                    typing: true
                });
            }, 300);
        }
    }
}
</script>       
<style scoped>
.sc-chat-popup {
    position: fixed;
    right: 50px;
    bottom: 0;
    z-index: 1000;
    width: 350px;
    text-align: initial;
    height: calc(100% - 200px);
    max-height: 590px;
    box-sizing: border-box;
    box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.85);
    background: white;    
    border-radius: 10px;
    animation: fadeIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
}
</style>