<template>
    <span>
        <button @click="showPopup" class="btn btn-default btn-sm">
            <i class="bx bx-chat m-0"></i>
            <span v-show="messageCount > 0" class="badge bg-success">
                {{ messageCount }} New
            </span>
        </button>
        <chat-popup v-show="openChat" :id="popupId" :bid="bid" @close="closeChat"></chat-popup>
    </span>
</template>

<script>
import ChatPopup from './ChatPopup.vue';

export default {
    name: "chatToggle",
    props: ['bid'],
    components: {
        'chat-popup': ChatPopup
    },
    data: function () {
        return {
            messageCount: 0,
            openChat: false,
            popupId: 'pop' + this.bid.id
        }
    },
    mounted() {
        
        window.Echo.private('chat.' + this.bid.conversation_id)
            .listen('.message.created', (e) => {
                this.messageCount++;
            });

        window.Echo.private('messages.' + window.Laravel.user.id)
            .listen('.messages.read', (e) => {
                this.geUnreadMessageCount();
            });
    },
    created() {
        this.geUnreadMessageCount();
    },
    methods: {
        displayPopups() {
            let right = 50;
            let i = 0;

            for (i; i < window.totalPopups; i++) {
                if (window.popups[i] != undefined) {
                    var element = document.getElementById(window.popups[i]);
                    element.style.right = right + "px";
                    right += 365
                    element.style.display = "block";
                }
            }

            for (let j = i; j < window.popups.length; j++) {
                var element = document.getElementById(window.popups[j]);
                element.style.display = "none";
            }
        },
        calculatePopups() {
            let width = window.innerWidth;

            if (width < 540) {
                window.totalPopups = 0;
            }
            else {
                width -= 50;
                window.totalPopups = parseInt(width / 350);
            }

            this.displayPopups();
        },
        showPopup() {
            let id = 'pop' + this.bid.id;

            for (let i = 0; i < window.popups.length; i++) {
                if (id == window.popups[i]) {
                    Array.remove(window.popups, i);

                    window.popups.unshift(id);

                    this.calculatePopups();

                    return;
                }
            }

            window.popups.unshift(id);

            this.calculatePopups();
        },
        closeChat(id) {
            for (var i = 0; i < popups.length; i++) {
                if (id == window.popups[i]) {
                    Array.remove(popups, i);

                    document.getElementById(id).style.display = "none";

                    this.calculatePopups();

                    return;
                }
            }
        },
        geUnreadMessageCount() {
            axios.get(`/messages/${this.bid.conversation_id}/unread-count`).then(result => {
                this.messageCount = result.data.count;
            }).catch(error => {
                console.log(error);
            })
        }
    }
}
</script>
<style scoped>
.btn-default{
    line-height: 1;
}
.bx-chat{
    font-size: 15px;
}
</style>
