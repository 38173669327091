<template>
    <span v-if="parseInt(messageCount) > 0" id="message-counter" class="notification">{{ messageCount }}</span>
</template>

<script>
import axios from 'axios';

export default {
    name: "UnreadMessagesCount",
    props: ['count'],
    data: function () {
        return {
            messageCount: this.count,
        }
    },
    mounted() {
        window.Echo.private('messages.' + window.Laravel.user.id)
            .listen('.messages.created', (e) => {
                this.getUnreadMessagesCount();
            });
    },
    methods: {
        getUnreadMessagesCount() {
            axios.get(`/messages/unread-count`).then(result => {
                this.messageCount = result.data.count;
            }).catch(error => {
                console.log(error);
            })
        }
    }
}
</script>
