<template>
    <div class="vue-countdown">
        <div class="vue-countdown--time">
            {{ time }}
        </div>
    </div>
</template>

<script>
    import EasyTimer from 'easytimer';

    export default {
        name: 'timer',
        props: {
            seconds: Number,
            countdown: Boolean,
            message: String,
            date: String,
            units: Array,
            start: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                timer: null,
                time: '',
                label: this.message ? this.message : 'Time\'s up!',
                timerUnits: this.units ? this.units : ['hours', 'minutes', 'seconds'],
                timerOptions: {}
            };
        },

        computed: {
            parsedDate() {
                if (!this.date) {
                    return false;
                }

                return Date.parse(this.date);
            }
        },

        created() {
            this.setTimer()
        },

        methods: {
            setTimer() {
                this.$emit('starting');

                this.timer = new EasyTimer();

                const parsedDate = this.parsedDate;
                const now = Date.now();

                let seconds = this.seconds;
                this.timerOptions = {
                    precision: "seconds",
                    countdown: true
                };

                if (!parsedDate && this.date) {
                    throw new Error('Please provide valid date');
                }

                if (now < parsedDate) {
                    seconds = (parsedDate - now) / 1000;
                }

                this.timerOptions.startValues = {
                    seconds: seconds
                };

                if (this.start) {
                    this.timer.start(this.timerOptions);
                }

                this.time = this.timer.getTimeValues().toString(this.timerUnits);

                this.timer.addEventListener('secondsUpdated', this.onTimeChange.bind(this));
                this.timer.addEventListener('targetAchieved', this.onTimeExpire.bind(this));
            },
            onTimeChange() {
                let timeValues = this.timer.getTimeValues();
                this.time = this.timer.getTimeValues().toString(this.timerUnits);
                this.checkEnding(timeValues);
            },

            checkEnding(timeValues) {
                if (timeValues.days === 0 && timeValues.hours === 0 && timeValues.minutes === 0 && timeValues.seconds === 10) {
                    this.$emit('timeEnding');
                }
            },

            onTimeExpire() {
                this.$emit('end');
                this.time = this.label;
            }
        },

        watch: {
            start(newValue) {
                if (newValue) {
                    this.timer.start(this.timerOptions);
                } else {
                    this.timer.stop();
                }
            }
        }
    };
</script>