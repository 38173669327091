$(function () {

    var select = '<select name="$file" data-rule-required="true" class="file-options input">' +
        '<option value="">--Select Description--</option>' +
        '<option value="Completed Paper">Completed Paper</option>' +
        '<option value="Chart">Chart</option>' +
        '<option value="Revised Paper">Revised Paper</option>' +
        '<option value="Plagiarism Report">Completed PPT</option>' +
        '<option value="Plagiarism Report">Revised PPT</option>' +
        '</select>';

    $('#submissions').MultiFile({
        list: '.submissions-list',
        STRING: {
            file: '$file ' + select,
        }
    });

    $('#sources').MultiFile({
        list: '.sources-list',
    });

    $("#submit-order-form").validate({
        rules: {
            'submissions[]': {
                required: true
            },
            'sources[]': {
                required: true
            },
        },
        messages: {
            'submissions[]': {
                required: 'Your submission is required.'
            },
            'sources[]': {
                required: 'Digital sources are required.'
            }
        },
        submitHandler: function (form) {
            form.submit();
        }
    });
});