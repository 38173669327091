<template>
    <div id="question" class="margin-top-10 padding-left-10 padding-top-10">
        <timer v-show="start" class="countdown" :class="{'ending': ending}"
               @starting="ending = false"
               @timeEnding="ending = true"
               @end="submitAnswer"
               :seconds="currentQuestion.time" :start="start">
        </timer>
        <p class="font-size-18">{{ currentQuestion.number }}. {{ currentQuestion.description }}</p>
        <div class="options">
            <div class="custom-control custom-radio" v-for="(option, index) in currentQuestion.options">
                <input type="radio" class="custom-control-input" :id="option.id" :value="option.id" v-model="selectedOption" :key="index">
                <label class="custom-control-label" :for="option.id">
                    {{ option.description }}
                    <i v-if="option.is_answer" class="bx bx-check-circle"></i>
                </label>
            </div>
        </div>
        <div class="text-right">
            <button @click="submitAnswer" class="btn btn-primary btn-round">
                <i class="bx bx-skip-next"></i> Next
            </button>
        </div>
    </div>
</template>

<script>
    import timer from "../CountDown";

    export default {
        name: "question",
        props: ["question", "questionUrl"],
        data() {
            return {
                start: true,
                ending: false,
                selectedOption: "",
                currentQuestion: this.question
            }
        },
        components: {
            timer
        },
        methods: {
            submitAnswer() {
                let data = {
                    option_id: this.selectedOption,
                    question_id: this.currentQuestion.id
                };
                this.start = false;
                axios.post(this.questionUrl, data).then((response) => {
                    if (response.data.evaluation === "pending") {
                        this.currentQuestion = response.data.question;
                        this.start = true;
                    } else if (response.data.evaluation === "complete") {
                        window.location.reload()
                    }
                }).catch((error) => {
                    this.errorMessage(error.data.message)
                })
            },
            errorMessage(message) {
                return swal({
                    title: 'Error',
                    text: message,
                    type: "warning",
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                    allowEscapeKey: false
                })
            },
        }
    }
</script>