$(document).ready(function () {
    if (!$.fn.dataTable.isDataTable('#withdrawalsTable')) {
        let table = $('#withdrawalsTable').DataTable({
            dom: 'frtip',
            processing: true,
            serverSide: true,
            autoWidth: false,
            ajax: window.location.protocol + "//" + window.location.hostname + "/wallet/withdrawals",
            columns: [
                {
                    className: 'details-control',
                    orderable: false,
                    searchable: false,
                    data: null,
                    defaultContent: '<i class="bx bx-plus-circle" style="cursor: pointer; font-size: 16px;"></i>',
                    title: '',
                    width: '20px',
                },
                {data: 'reference', name: 'reference'},
                {data: 'payment_method.name', name: 'paymentMethod.name'},
                {data: 'status', name: 'status'},
                {data: 'processed_at', name: 'processed_at', className: 'text-center'},
                {data: 'amount', name: 'amount', className: 'bold'}
            ],
            order: [[4, 'desc']]
        });


        $('#withdrawalsTable tbody').on('click', 'td.details-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);
            let icon = $(this).find('i');

            if (row.child.isShown()) {
                row.child.hide();
                tr.removeClass('shown');
                icon.removeClass('fa-minus-circle').addClass('fa-plus-circle');
            }
            else {
                row.child(rowDetails(row.data())).show();
                tr.addClass('shown');
                icon.addClass('fa-minus-circle').removeClass('fa-plus-circle');
            }
        });
    }
});

/**
 * Format a number similar to PHP number format.
 *
 * @param x
 * @returns {string}
 */
function numberFormat(x) {
    let number = Number(x).toFixed(2);
    let parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

/**
 * Render the details of a withdrawal.
 * @return {string}
 * @param data
 */
function rowDetails(data) {
    let details = '<div class="row">' +
        '<div class="col-sm-7">' +
        '<table class="table">';

    details += renderOrders(data.payment_items.orders, details);

    if (data.payment_items.fines.length > 0) {
        details += renderFines(data.payment_items.fines, details);
    }

    details += '</table></div>';

    details += '<div class="col-sm-5">';
    details += renderPaymentMethod(data.payment_method.code, data.payment_method_details);
    details += '</div>';

    details += '</div>';

    return details;
}

/**
 * Render the orders associated with the withdrawals.
 *
 * @param array orders
 * @returns {string}
 */
function renderOrders(orders) {
    let details = '<tr>' +
        '<th>#Order</th>' +
        '<th>Discipline</th>' +
        '<th>Pages</th>' +
        '<th>Accepted At</th>' +
        '<th width="10%">Amount</th>' +
        '</tr>';

    $(orders).each(function (index, order) {
        details +=
            '<tr>' +
            '<td>' +
            '<a href="' + window.location.protocol + '//' + window.location.hostname + '/orders/' + order.order_id + '">' +
            order.order_id +
            '</a>' +
            '</td>' +
            '<td>' + order.discipline + '</td>' +
            '<td>' + order.pages + '</td>' +
            '<td>' + order.accepted_at + '</td>' +
            '<td>$' + numberFormat(order.amount) + '</td>' +
            '</tr>';
    });

    return details;
}

/**
 * Render the fines associated with the withdrawal.
 *
 * @param array fines
 * @returns {string}
 */
function renderFines(fines) {
    let details = '<tr><th colspan="3">Fines</th></tr>';

    $(fines).each(function (index, fine) {
        details +=
            '<tr>' +
            '<td><a href="' + window.location.protocol + '//' + window.location.hostname + '/orders/' + fine.order_id + '">'
            + fine.order_id +
            '</a></td>' +
            '<td colspan="3">' + fine.type + '</td>' +
            '<td width="10%">$' + numberFormat(fine.amount) + '</td>' +
            '</tr>';
    });

    return details;
}

/**
 * Render a payment method associated with the withdrawal.
 *
 * @param string paymentMethod
 * @param object paymentMethodDetails
 * @returns {*}
 */
function renderPaymentMethod(paymentMethod, paymentMethodDetails) {
    switch (paymentMethod) {
        case 'paypal':
            return renderPayPal(paymentMethodDetails);
        case 'bank':
            return renderBankDetails(paymentMethodDetails);
        case 'mpesa':
            return renderMpesaDetails(paymentMethodDetails);
        default:
            return '';
    }
}

/**
 * Render PayPal payment method details.
 *
 * @param paymentMethodDetails
 * @returns {string}
 */
function renderPayPal(paymentMethodDetails) {
    return '<div class="well">' +
        '<div class="row">' +
        '<div class="col-sm-2">' +
        '<div class="payment-icon">' +
        '<i class="bx bx-paypal"></i>' +
        '</div>' +
        '</div>' +
        '<div class="col-sm-10">' +
        '<table>' +
        '<tr>' +
        '<td><strong>Name:</strong>' + paymentMethodDetails.paypal_name + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td><strong>Email:</strong>' + paymentMethodDetails.paypal_email + '</td>' +
        '</tr>' +
        '</table>' +
        '</div>' +
        '</div>' +
        '</div>';
}

/**
 * Render Bank payment method details.
 *
 * @param paymentMethodDetails
 * @returns {string}
 */
function renderBankDetails(paymentMethodDetails) {
    return '<div class="well">' +
        '<div class="row">' +
        '<div class="col-sm-2">' +
        '<div class="payment-icon">' +
        '<i class="bx bx-bank"></i>' +
        '</div>' +
        '</div>' +
        '<div class="col-sm-10">' +
        '<table>' +
        '<tr>' +
        '<td><strong>Bank:</strong>' + paymentMethodDetails.bank_name + '</td>' +
        '</tr>' +
        '<tr>' +
        '<tr>' +
        '<td><strong>A/C Name:</strong>' + paymentMethodDetails.account_name + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td><strong>A/C Number:</strong>' + paymentMethodDetails.account_number + '</td>' +
        '</tr>' +
        '</table>' +
        '</div>' +
        '</div>' +
        '</div>';
}

/**
 * Render Bank payment method details.
 *
 * @param paymentMethodDetails
 * @returns {string}
 */
function renderMpesaDetails(paymentMethodDetails) {
    return '<div class="well">' +
        '<div class="row">' +
        '<div class="col-sm-2">' +
        '<div class="payment-icon">' +
        '<i class="bx bx-mobile-phone"></i>' +
        '</div>' +
        '</div>' +
        '<div class="col-sm-10">' +
        '<table>' +
        '<tr>' +
        '<tr>' +
        '<td><strong>Name:</strong>' + paymentMethodDetails.mpesa_name + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td><strong>Phone Number:</strong>' + paymentMethodDetails.mpesa_number + '</td>' +
        '</tr>' +
        '</table>' +
        '</div>' +
        '</div>' +
        '</div>';
}